import Footer from "./Footer";
import Header from "./Header";
import React from "react";
import educationImage from "../images/education.png";
import genderEmpowermentImage from "../images/gender-empowernment.png";
import internshipImage from "../images/internship.png";
import { Link } from "react-router-dom";

const ProgramOverview: React.FC = () => {
    const programs = [
        {
            label: "Education",
            value: 60,
            color: "#34D399",
            description: "Providing access to quality education and resources for youth development."
        },
        {
            label: "Internships",
            value: 40,
            color: "#3B82F6",
            description: "Offering real-world experience through partnerships with local and international organizations."
        },
        {
            label: "Scholarships",
            value: 30,
            color: "#F87171",
            description: "Supporting talented students with financial aid to pursue higher education."
        },
        {
            label: "Gender Empowerment",
            value: 50,
            color: "#60A5FA",
            description: "Promoting gender equality through targeted workshops and events."
        },
    ];

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {programs.map((program, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold mb-2" style={{ color: program.color }}>{program.label}</h3>
                    <div className="flex items-center mb-4">
                        <div className="w-full bg-gray-200 rounded-full h-4 mr-2">
                            <div
                                className="h-full rounded-full"
                                style={{
                                    width: `${program.value}%`,
                                    backgroundColor: program.color,
                                    transition: 'width 1s ease-out'
                                }}
                            />
                        </div>
                        <span className="text-sm font-bold">{program.value}%</span>
                    </div>
                    <p className="text-gray-600">{program.description}</p>
                </div>
            ))}
        </div>
    );
};

const AnimatedImage: React.FC<{ src: string; alt: string }> = ({ src, alt }) => {
    return (
        <div className="overflow-hidden rounded-lg shadow-md">
            <img
                src={src}
                alt={alt}
                className="w-full h-auto transform transition-transform duration-500 ease-in-out hover:scale-105"
            />
        </div>
    );
};

const ProgramsPage: React.FC = () => {
    const educationUrl = "https://global.ed.ac.uk/mastercard-foundation/apply-for-a-scholarship/on-campus-postgraduate-scholarships";
    const internshipsUrl = "https://www.facebook.com/share/p/1d9ZSVwqtXkkXtv3/";
    const genderEmpowermentUrl = "https://global.ed.ac.uk/mastercard-foundation/apply-for-a-scholarship/on-campus-postgraduate-scholarships";

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow pt-32 md:pt-40">
                {/* Programs Overview Section */}
                <section className="bg-gray-100 py-16">
                    <div className="max-w-6xl mx-auto px-4">
                        <h1 className="text-4xl font-bold mb-4 text-center">
                            <span className="text-green-700">Programs</span> Overview
                        </h1>
                        <p className="text-xl mb-8 text-center">
                            Empowering Madagascar's youth through diverse initiatives
                        </p>
                        <ProgramOverview />
                    </div>
                </section>

                {/* Education and Resources Section */}
                <section className="bg-white py-16">
                    <div className="max-w-6xl mx-auto px-4 flex flex-col md:flex-row items-center">
                        <div className="w-full md:w-1/2 pr-0 md:pr-8 mb-8 md:mb-0">
                            <h2 className="text-3xl font-bold mb-4 text-green-700">Education and Resources</h2>
                            <p className="mb-6">
                                At EMY, we offer programs that open doors for Madagascar's youth. From English language courses to computer skills
                                training and vocational opportunities, we're here to help young people build the skills they need for a brighter future.
                            </p>
                            <Link to={educationUrl} className="bg-red-500 text-white px-8 py-3 rounded-full hover:bg-red-600 inline-block transition duration-300 ease-in-out transform hover:scale-105">
                                View more
                            </Link>
                        </div>
                        <div className="w-full md:w-1/2">
                            <AnimatedImage src={educationImage} alt="Education and Resources" />
                        </div>
                    </div>
                </section>

                {/* Internships & Scholarships Section */}
                <section className="bg-gray-100 py-16">
                    <div className="max-w-6xl mx-auto px-4 flex flex-col-reverse md:flex-row items-center">
                        <div className="w-full md:w-1/2 mt-8 md:mt-0">
                            <AnimatedImage src={internshipImage} alt="Internships & Scholarships" />
                        </div>
                        <div className="w-full md:w-1/2 pl-0 md:pl-8">
                            <h2 className="text-3xl font-bold mb-4 text-green-700">Internships & Scholarships</h2>
                            <p className="mb-6">
                                Through EMY, young people can access internships and scholarships that provide real-world experience and valuable
                                opportunities. We're committed to helping Madagascar's youth gain the skills and connections they need to succeed in
                                their careers.
                            </p>
                            <Link to={internshipsUrl} className="bg-red-500 text-white px-8 py-3 rounded-full hover:bg-red-600 inline-block transition duration-300 ease-in-out transform hover:scale-105">
                                View more
                            </Link>
                        </div>
                    </div>
                </section>

                {/* Gender Empowerment Events Section */}
                <section className="bg-white py-16">
                    <div className="max-w-6xl mx-auto px-4 flex flex-col md:flex-row items-center">
                        <div className="w-full md:w-1/2 pr-0 md:pr-8 mb-8 md:mb-0">
                            <h2 className="text-3xl font-bold mb-4 text-green-700">Gender Empowerment Events</h2>
                            <p className="mb-6">
                                EMY hosts workshops, awareness campaigns, and events dedicated to empowering women and promoting gender
                                equality in Madagascar. These initiatives provide a platform for women to build confidence, develop skills, and take on
                                leadership roles, helping to create a more inclusive and equitable future.
                            </p>
                            <Link to={genderEmpowermentUrl} className="bg-red-500 text-white px-8 py-3 rounded-full hover:bg-red-600 inline-block transition duration-300 ease-in-out transform hover:scale-105">
                                View more
                            </Link>
                        </div>
                        <div className="w-full md:w-1/2">
                            <AnimatedImage src={genderEmpowermentImage} alt="Gender Empowerment Events" />
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default ProgramsPage;