import Footer from "./components/Footer";
import React from "react";
import landingPage from "./images/landingPage.png";
import logo from "./images/logo.png";
import { Link } from "react-router-dom";

const Button: React.FC<{ href: string; className: string; children: React.ReactNode }> = ({ href, className, children }) => (
    <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={`${className} px-6 py-3 rounded-lg text-center text-base md:text-lg font-semibold transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg w-full sm:w-auto`}
    >
        {children}
    </a>
);

const LandingPage: React.FC = () => {
    return (
        <div className="bg-gray-100 min-h-screen">
            <div className="bg-white shadow-lg p-4 md:p-8 max-w-6xl mx-auto my-4 md:my-8 rounded-lg">
                <h1>Click Me</h1>
                <div className="flex justify-between items-center mb-6">
                    <Link to="/home" className="flex items-center space-x-2 hover:opacity-80 transition-opacity">
                        <img src={logo} alt="EMY Logo" className="h-12 md:h-16" />

                    </Link>
                </div>

                <div className="flex flex-col md:flex-row gap-8 mb-8">
                    <div className="md:w-1/2 flex flex-col justify-center">
                        <h1 className="text-3xl md:text-5xl font-bold mb-4 text-green-700">Empower Madagascar Youth</h1>
                        <p className="text-lg md:text-xl mb-6 text-gray-600">Building Futures, One Opportunity at a Time!</p>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                            <Button
                                href="https://forms.gle/MY45VbmpuUAS2o6N7"
                                className="bg-green-600 text-white hover:bg-green-700"
                            >
                                Join Us!
                            </Button>
                            <Button
                                href="https://forms.gle/sfuuKcDTxGx7vuvZ6"
                                className="bg-red-500 text-white hover:bg-red-600"
                            >
                                Donate
                            </Button>
                            <Button
                                href="https://forms.gle/NennFdogy7NCYqWi6"
                                className="bg-blue-500 text-white hover:bg-blue-600"
                            >
                                Volunteer
                            </Button>
                        </div>
                    </div>
                    <div className="md:w-1/2">
                        <img src={landingPage} alt="Madagascar Youth" className="w-full h-auto rounded-lg shadow-md" />
                    </div>
                </div>

                <div className="bg-red-500 text-white p-6 rounded-lg mb-8 shadow-md">
                    <p className="text-lg leading-relaxed">
                        We are a non-profit organization dedicated to providing education, internships, and empowerment opportunities for Madagascar's youth, especially women. Join us today, and together we can create opportunities for all young people to thrive and achieve their potential.
                    </p>
                </div>

                <Footer />
            </div>
        </div>
    );
};

export default LandingPage;