import React from "react";
import { faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
    return (
        <footer className="bg-white py-8">
            <div className="max-w-6xl mx-auto px-4">
                <nav className="grid grid-cols-2 md:grid-cols-5 gap-8 mb-8">
                    <div>
                        <h3 className="font-semibold mb-2">Empower Madagascar Youth (EMY)</h3>
                        <p className="text-sm text-gray-600">Building Futures, One Opportunity at a Time!</p>
                    </div>
                    <div>
                        <h3 className="font-semibold mb-2">Programs</h3>
                        <ul className="text-sm space-y-1">
                            <li><Link to="/programs/" className="text-gray-600 hover:underline">Gender Empowerment</Link></li>
                            <li><Link to="/programs/" className="text-gray-600 hover:underline">Internships & Scholarships</Link></li>
                            <li><Link to="/programs/" className="text-gray-600 hover:underline">Education & Resources</Link></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="font-semibold mb-2">Support us</h3>
                        <ul className="text-sm space-y-1">
                            <li><Link to="/get-involved" className="text-gray-600 hover:underline">Get Involved</Link></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="font-semibold mb-2">About Us</h3>
                        <ul className="text-sm space-y-1">
                            <li><Link to="/about/" className="text-gray-600 hover:underline">Our Mission</Link></li>
                            <li><Link to="/about/" className="text-gray-600 hover:underline">Our Vision</Link></li>
                            <li><Link to="/about/" className="text-gray-600 hover:underline">Our Values</Link></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="font-semibold mb-2">Resources</h3>
                        <ul className="text-sm space-y-1">
                            <li><Link to="/#" className="text-gray-600 hover:underline">News</Link></li>
                            <li><Link to="/#" className="text-gray-600 hover:underline">Blog</Link></li>
                            <li><Link to="/#" className="text-gray-600 hover:underline">Gallery</Link></li>
                        </ul>
                    </div>
                </nav>
                <div className="flex items-center justify-between border-t pt-8">
                    <p className="text-sm text-gray-600">Connect with EMY:</p>
                    <div className="flex space-x-4">
                        <a href="#" className="text-gray-600 hover:text-green-600"><FontAwesomeIcon icon={faInstagram} /></a>
                        <a href="#" className="text-gray-600 hover:text-blue-400"><FontAwesomeIcon icon={faTwitter} /></a>
                        <a href="#" className="text-gray-600 hover:text-blue-600"><FontAwesomeIcon icon={faLinkedin} /></a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;