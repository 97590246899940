import "./index.css";
import AboutPage from "./components/AboutPage";
import ContactUs from "./components/ContactUs";
import DonatePage from "./components/DonatePage";
import GetInvolvedPage from "./components/GetInvolvedPage";
import HomePage from "./components/HomePage";
import LandingPage from "./LandingPage";
import ProgramsPage from "./components/ProgramsPage";
import React from "react";
import TeamPage from "./components/TeamPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const Home = () => <h2>Home Page</h2>;
const Programs = () => <h2>Programs Page</h2>;
const About = () => <h2>About Us Page</h2>;
const Support = () => <h2>Support Us Page</h2>;
const Resources = () => <h2>Resources Page</h2>;

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/programs" element={<ProgramsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/get-involved" element={<GetInvolvedPage />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/donate" element={<DonatePage />} />
        <Route path="/team" element={<TeamPage />} />

      </Routes>
    </Router>
  );
};

export default App;