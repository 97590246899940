import Footer from "./Footer";
import Header from "./Header";
import React from "react";
import annaImage from "../images/anna.jpeg";
import evelynImage from "../images/sarah.jpeg";
import johnImage from "../images/stan.jpeg";
import michaelImage from "../images/michael.jpeg";
import sarahImage from "../images/john.jpeg";
import stanImage from "../images/stan.jpeg";

// Import team member images

const teamMembers = [
    { name: 'John M. Claude', role: 'Co-Founder | Project | Program Coordinator | Educator', image: johnImage },
    { name: 'Sarah James', role: 'Co-Founder | Early Program Coordinator | Outreach Director', image: sarahImage },
    { name: 'Michael G. Akumba', role: 'Co-Founder | Tech Program Coordinator | Outreach Manager', image: michaelImage },
    { name: 'Anna Jones', role: 'Co-Founder | Project | Program Coordinator | Educator', image: annaImage },
    { name: 'Stan Johnson', role: 'Co-Founder | Tech Program Coordinator | Outreach Manager', image: stanImage },
    { name: 'Evelyn Paul', role: 'Co-Founder | Early Program Coordinator | Outreach Director', image: evelynImage },
];

const TeamPage: React.FC = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow">
                <div className="max-w-6xl mx-auto px-4 py-12">
                    <h1 className="text-4xl font-bold mb-2 text-center">
                        Team <span className="text-green-700">Profile</span>
                    </h1>
                    <p className="text-xl mb-8 text-center">
                        Meet the team of innovative and powerful <span className="text-red-500">Minds!</span>
                    </p>

                    <h2 className="text-3xl font-bold mb-6 text-green-700">The Team</h2>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {teamMembers.map((member, index) => (
                            <div key={index} className="bg-green-600 rounded-lg overflow-hidden shadow-lg">
                                <img src={member.image} alt={member.name} className="w-full h-48 object-cover" />
                                <div className="p-4 text-white">
                                    <h3 className="font-bold text-lg mb-2">{member.name}</h3>
                                    <p className="text-sm">{member.role}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default TeamPage;