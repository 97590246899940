import Facebook from "../images/facebook.png";
import Footer from "./Footer";
import Gmail from "../images/gmail.png";
import Header from "./Header";
import HeroContact from "../images/contact-hero.png";
import Instagram from "../images/instagram.png";
import Linkedin from "../images/linkedin.png";
import React, { useState } from "react";
import Twitter from "../images/twitter.png";
import { Link } from "react-router-dom";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        fullName: "",
        phoneNumber: "",
        email: "",
        requestInfo: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitMessage, setSubmitMessage] = useState("");

    const recipientEmail = "alanajoshua535@gmail.com";

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmitMessage("");

        const dataToSend = {
            ...formData,
            to: recipientEmail,
            subject: "New Contact Form Submission",
        };

        try {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            console.log("Form data to be sent:", dataToSend);
            setSubmitMessage("Your message has been sent successfully!");
            setFormData({ fullName: "", phoneNumber: "", email: "", requestInfo: "" });
        } catch (error) {
            setSubmitMessage("There was an error sending your message. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow">
                {/* Hero Section */}
                <section className="relative bg-gray-900 text-white py-20">
                    <div className="absolute inset-0 overflow-hidden">
                        <img src={HeroContact} alt="Contact Us" className="w-full h-full object-cover opacity-30" />
                    </div>
                    <div className="relative max-w-6xl mx-auto px-4 z-10">
                        <h1 className="text-5xl md:text-6xl font-bold mb-4">
                            Get in <span className="text-green-400">Touch</span>
                        </h1>
                        <p className="text-xl md:text-2xl mb-8">We're here to answer any questions you may have</p>
                        <a href="#contact-form" className="bg-red-500 text-white px-8 py-3 rounded-full text-lg hover:bg-red-600 transition duration-300">
                            Contact Us Now
                        </a>
                    </div>
                </section>

                {/* Social Media Links */}
                <section className="py-16 bg-white">
                    <div className="max-w-6xl mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-8 text-green-700 text-center">Connect With Us</h2>
                        <div className="flex flex-wrap justify-center gap-8">
                            {[
                                { icon: Gmail, link: `mailto:${recipientEmail}`, alt: "Gmail" },
                                { icon: Linkedin, link: "https://www.linkedin.com/your-profile", alt: "LinkedIn" },
                                { icon: Twitter, link: "https://twitter.com/your-profile", alt: "Twitter" },
                                { icon: Instagram, link: "https://www.instagram.com/your-profile", alt: "Instagram" },
                                { icon: Facebook, link: "https://www.facebook.com/your-profile", alt: "Facebook" },
                            ].map((social, index) => (
                                <a
                                    key={index}
                                    href={social.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="transform hover:scale-110 transition-transform duration-200"
                                >
                                    <img src={social.icon} alt={social.alt} className="h-12 w-12 object-contain" />
                                </a>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Contact Form */}
                <section id="contact-form" className="bg-gray-100 py-16">
                    <div className="max-w-3xl mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-8 text-green-700 text-center">Request Information</h2>
                        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-8">
                            <div className="mb-6">
                                <label htmlFor="fullName" className="block text-gray-700 font-bold mb-2">
                                    Full Name*
                                </label>
                                <input
                                    type="text"
                                    id="fullName"
                                    name="fullName"
                                    required
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                    placeholder="John Doe"
                                />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="phoneNumber" className="block text-gray-700 font-bold mb-2">
                                    Phone Number
                                </label>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                    placeholder="+1 (123) 456-7890"
                                />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
                                    Email*
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    required
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                    placeholder="johndoe@example.com"
                                />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="requestInfo" className="block text-gray-700 font-bold mb-2">
                                    Request Information*
                                </label>
                                <textarea
                                    id="requestInfo"
                                    name="requestInfo"
                                    required
                                    rows={4}
                                    value={formData.requestInfo}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                    placeholder="Please provide details about your request..."
                                ></textarea>
                            </div>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="w-full bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700 transition duration-300 disabled:bg-gray-400"
                            >
                                {isSubmitting ? "Submitting..." : "Submit Request"}
                            </button>
                            {submitMessage && (
                                <p className={`mt-4 text-center ${submitMessage.includes("error") ? "text-red-600" : "text-green-600"}`}>
                                    {submitMessage}
                                </p>
                            )}
                        </form>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default ContactUs;