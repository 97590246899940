import React from "react";

const DonatePage: React.FC = () => {
    // Replace this URL with your actual Google Form embed URL
    const googleFormUrl = "https://forms.gle/sfuuKcDTxGx7vuvZ6"
    return (
        <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
            <h2 className="text-3xl font-bold text-center mb-6 text-green-700">Donate to EMY</h2>
            <p className="text-center mb-6">Your support helps us empower Madagascar's youth!</p>

            <div className="aspect-w-16 aspect-h-9">
                <iframe
                    src={googleFormUrl}
                    width="100%"
                    height="700"
                    frameBorder="0"
                    marginHeight={0}
                    marginWidth={0}
                    className="rounded-lg shadow-md"
                >
                    Loading…
                </iframe>
            </div>

            <p className="mt-4 text-sm text-gray-600 text-center">
                If the form doesn't load, you can also
                <a
                    href={googleFormUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-green-600 hover:text-green-800 ml-1"
                >
                    open it in a new tab
                </a>.
            </p>
        </div>
    );
};

export default DonatePage;