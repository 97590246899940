import Footer from "./Footer";
import Header from "./Header";
import HeroVideo from "../images/video.mp4";
import React from "react";
import educationImage from "../images/education.jpg";
import genderEmpowermentImage from "../images/gender-empowerment.jpg";
import handsImage from "../images/hands.png";
import heroImage from "../images/hero-involved.png";
import heroInvolved from "../images/gender-empowerment.jpg";
import internshipsImage from "../images/internships.jpg";
import { Link } from "react-router-dom";

const GetInvolvedPage = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow">
                {/* Hero Section */}
                <section className="relative h-screen flex items-center justify-center overflow-hidden">
                    <video
                        autoPlay
                        loop
                        muted
                        className="absolute z-10 w-auto min-w-full min-h-full max-w-none"
                    >
                        <source src={HeroVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="z-20 text-center text-white">
                        <h1 className="text-5xl md:text-6xl font-bold mb-4">
                            <span className="text-red-500">Become a part of</span>
                            <br />
                            <span className="text-green-400">Empower</span>
                            <br />
                            Madagascar Youth
                        </h1>
                        <p className="text-xl md:text-2xl mb-8">Join us in making a difference</p>
                        <Link
                            to="#get-involved"
                            className="bg-red-500 text-white px-8 py-3 rounded-full text-lg hover:bg-red-600 transition duration-300"
                        >
                            Get Involved
                        </Link>
                    </div>
                </section>

                {/* Get Involved Options */}
                <section id="get-involved" className="py-16 bg-gray-100">
                    <div className="max-w-6xl mx-auto px-4">
                        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-green-700">
                            How You Can Help
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                            {/* Volunteer Card */}
                            <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:scale-105">
                                <img src={heroImage} alt="Volunteer" className="w-full h-48 object-cover" />
                                <div className="p-6">
                                    <h3 className="text-xl font-bold mb-2 text-green-700">
                                        Become a Volunteer
                                    </h3>
                                    <p className="text-gray-600 mb-4">
                                        Join us in empowering Madagascar's youth through mentoring and supporting our programs.
                                    </p>
                                    <Link
                                        to="https://forms.gle/NennFdogy7NCYqWi6"
                                        className="block text-center bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 transition duration-300"
                                    >
                                        Volunteer Now
                                    </Link>
                                </div>
                            </div>

                            {/* Donate Card */}
                            <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:scale-105">
                                <img src={handsImage} alt="Donate" className="w-full h-48 object-cover" />
                                <div className="p-6">
                                    <h3 className="text-xl font-bold mb-2 text-green-700">
                                        Make a Donation
                                    </h3>
                                    <p className="text-gray-600 mb-4">
                                        Your support helps provide education and opportunities for young people in Madagascar.
                                    </p>
                                    <Link
                                        to="https://forms.gle/sfuuKcDTxGx7vuvZ6"
                                        className="block text-center bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 transition duration-300"
                                    >
                                        Donate Now
                                    </Link>
                                </div>
                            </div>

                            {/* Partner Card */}
                            <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:scale-105">
                                <img src={heroInvolved} alt="Partner" className="w-full h-48 object-cover" />
                                <div className="p-6">
                                    <h3 className="text-xl font-bold mb-2 text-green-700">
                                        Become a Partner
                                    </h3>
                                    <p className="text-gray-600 mb-4">
                                        Collaborate with us to create lasting impact and drive meaningful change in Madagascar.
                                    </p>
                                    <Link
                                        to="https://forms.gle/MY45VbmpuUAS2o6N7"
                                        className="block text-center bg-green-600 text-white px-4 py-2 rounded-full hover:bg-green-700 transition duration-300"
                                    >
                                        Partner with Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Current Programs Section */}
                <section className="py-16 bg-white">
                    <div className="max-w-6xl mx-auto px-4">
                        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-green-700">
                            Our Current Programs
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                            {/* Education Program */}
                            <Link to="https://global.ed.ac.uk/mastercard-foundation/apply-for-a-scholarship/on-campus-postgraduate-scholarships" className="group">
                                <div className="relative overflow-hidden rounded-lg shadow-lg">
                                    <img
                                        src={educationImage}
                                        alt="Education"
                                        className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-110"
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                        <span className="text-white text-lg font-bold">Learn More</span>
                                    </div>
                                </div>
                                <h3 className="mt-4 text-xl font-bold text-green-700">Education</h3>
                            </Link>

                            {/* Internships & Scholarships Program */}
                            <Link to="https://www.facebook.com/share/p/1d9ZSVwqtXkkXtv3/" className="group">
                                <div className="relative overflow-hidden rounded-lg shadow-lg">
                                    <img
                                        src={internshipsImage}
                                        alt="Internships & Scholarships"
                                        className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-110"
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                        <span className="text-white text-lg font-bold">Learn More</span>
                                    </div>
                                </div>
                                <h3 className="mt-4 text-xl font-bold text-green-700">Internships & Scholarships</h3>
                            </Link>

                            {/* Gender Empowerment Program */}
                            <Link to="https://www.facebook.com/share/p/1d9ZSVwqtXkkXtv3/" className="group">
                                <div className="relative overflow-hidden rounded-lg shadow-lg">
                                    <img
                                        src={genderEmpowermentImage}
                                        alt="Gender Empowerment Events"
                                        className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-110"
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                        <span className="text-white text-lg font-bold">Learn More</span>
                                    </div>
                                </div>
                                <h3 className="mt-4 text-xl font-bold text-green-700">Gender Empowerment Events</h3>
                            </Link>
                        </div>
                        <div className="text-center mt-12">
                            <Link
                                to="/programs"
                                className="inline-block bg-green-600 text-white px-8 py-3 rounded-full text-lg hover:bg-green-700 transition duration-300"
                            >
                                View All Programs
                            </Link>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default GetInvolvedPage;