import Footer from "./Footer";
import Header from "./Header";
import Hero1 from "../images/hero-header2.jpeg";
import Hero2 from "../images/hero-header3.jpeg";
import Hero3 from "../images/hero-header.jpeg";
import React, { useEffect, useState } from "react";
import Test1 from "../images/testi.png";
import WhoWeAre from "../images/whoweare.png";
import { Link } from "react-router-dom";

const heroImages = [Hero1, Hero2, Hero3];
const heroContent = [
    {
        title: "Empower Madagascar Youth",
        subtitle: "Building a brighter future for Madagascar's youth",
        description: "We provide education, skills training, and opportunities to empower the next generation of leaders in Madagascar.",
        stats: [
            { value: "5000+", label: "Youth Empowered" },
            { value: "50+", label: "Programs" },
            { value: "20+", label: "Partners" }
        ]
    },
    {
        title: "Building Futures Together",
        subtitle: "Collaborative efforts for sustainable change",
        description: "Through partnerships and community engagement, we're creating lasting impact in education and youth development.",
        stats: [
            { value: "100+", label: "Community Projects" },
            { value: "30+", label: "Schools Supported" },
            { value: "1000+", label: "Volunteers" }
        ]
    },
    {
        title: "Creating Opportunities for All",
        subtitle: "Inclusive programs for diverse youth",
        description: "Our initiatives focus on gender equality, rural outreach, and accessible education for all Malagasy youth.",
        stats: [
            { value: "60%", label: "Female Participants" },
            { value: "40+", label: "Rural Communities Reached" },
            { value: "10+", label: "Scholarship Programs" }
        ]
    }
];


const AnimatedHeroSection: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
        }, 7000); // Change content every 7 seconds

        return () => clearInterval(interval);
    }, []);

    const content = heroContent[currentIndex];

    return (
        <section className="relative h-screen overflow-hidden">
            {heroImages.map((image, index) => (
                <div
                    key={index}
                    className={`absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ${index === currentIndex ? 'opacity-100' : 'opacity-0'
                        }`}
                    style={{ backgroundImage: `url(${image})` }}
                />
            ))}
            <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center">
                <div className="max-w-6xl mx-auto px-4 text-white">
                    <h1 className="text-5xl font-bold mb-4 transition-opacity duration-1000">
                        {content.title}
                    </h1>
                    <p className="text-2xl mb-6 animate-fade-in-up">
                        {content.subtitle}
                    </p>
                    <p className="text-lg mb-8 max-w-2xl animate-fade-in-up delay-300">
                        {content.description}
                    </p>
                    <div className="flex justify-center space-x-8 mb-12 animate-fade-in-up delay-500">
                        {content.stats.map((stat, index) => (
                            <div key={index} className="text-center">
                                <p className="text-4xl font-bold">{stat.value}</p>
                                <p className="text-sm">{stat.label}</p>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center space-x-4 animate-fade-in-up delay-700">
                        <Link to="/programs" className="bg-green-600 text-white px-6 py-3 rounded-full hover:bg-green-700 transition duration-300">
                            Explore Programs
                        </Link>
                        <Link to="/get-involved" className="bg-white text-green-700 px-6 py-3 rounded-full hover:bg-gray-100 transition duration-300">
                            Get Involved
                        </Link>
                    </div>
                </div>
            </div>
            <div className="absolute bottom-5 left-0 right-0 flex justify-center">
                {heroImages.map((_, index) => (
                    <button
                        key={index}
                        className={`h-3 w-3 rounded-full mx-2 ${index === currentIndex ? 'bg-white' : 'bg-gray-400'}`}
                        onClick={() => setCurrentIndex(index)}
                    />
                ))}
            </div>
        </section>
    );
};

const testimonials = [
    {
        name: "Sarah",
        content: "Discover how Sarah's journey with Empower Madagascar Youth helped her unlock new opportunities and grow into a confident leader.",
        image: Test1
    },
    {
        name: "John",
        content: "John's experience with EMY's internship program opened doors to a career he never thought possible. His story is an inspiration to many.",
        image: Test1
    },
    {
        name: "Maria",
        content: "Through EMY's mentorship, Maria found her passion for community development. She's now leading initiatives in her village.",
        image: Test1
    },
    {
        name: "David",
        content: "EMY's education program gave David the skills he needed to start his own business. He's now employing other local youth.",
        image: Test1
    },
    {
        name: "Aina",
        content: "Aina's leadership skills blossomed through EMY's workshops. She's become a role model for young women in her community.",
        image: Test1
    }
];

const TestimonialSlider: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 5000); // Change testimonial every 5 seconds

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="overflow-hidden">
            <div
                className="flex transition-transform duration-500 ease-in-out"
                style={{ transform: `translateX(-${currentIndex * (100 / 3)}%)` }}
            >
                {testimonials.concat(testimonials.slice(0, 2)).map((testimonial, index) => (
                    <div key={index} className="w-full md:w-1/3 flex-shrink-0 px-4">
                        <div className="bg-gray-100 p-6 rounded-lg shadow h-full">
                            <img src={testimonial.image} alt={`Testimonial ${index + 1}`} className="w-full h-48 object-cover mb-4 rounded" />
                            <h3 className="text-xl font-bold mb-2">Meet {testimonial.name}</h3>
                            <p>{testimonial.content}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const HomePage: React.FC = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow">
                <AnimatedHeroSection />

                {/* Who We Are Section */}
                <section className="py-16 bg-white">
                    <div className="max-w-6xl mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-8 text-center text-green-700">Who we are</h2>
                        <p className="text-lg mb-8">
                            Empower Madagascar Youth (EMY) is a non-profit organization committed to uplifting the youth of Madagascar, with a
                            special focus on empowering women. Through education, internships, and skill-building programs, EMY provides young
                            people with the tools and opportunities they need to succeed. Our mission is to create a brighter future by fostering
                            leadership, enhancing career prospects, and promoting gender equality among Madagascar's youth.
                        </p>
                        <img src={WhoWeAre} alt="Madagascar Youth" className="w-full rounded-lg shadow-lg" />
                    </div>
                </section>

                {/* Our Impact Section */}
                <section className="py-16 bg-gray-100">
                    <div className="max-w-6xl mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-12 text-center text-green-700">Our Impact</h2>
                        <div className="flex justify-around text-center">
                            <div>
                                <p className="text-4xl font-bold mb-2">2024</p>
                                <p className="text-xl">Founded</p>
                            </div>
                            <div>
                                <p className="text-4xl font-bold mb-2 text-red-500">1000+</p>
                                <p className="text-xl">Youths Reached</p>
                            </div>
                            <div>
                                <p className="text-4xl font-bold mb-2">$4M+</p>
                                <p className="text-xl">Funds Raised</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Testimonials Section */}
                <section className="py-16 bg-white">
                    <div className="max-w-6xl mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-8 text-center text-green-700">Testimonials</h2>
                        <p className="text-lg mb-12 text-center">
                            Hear from the young people whose lives have been changed by Empower Madagascar Youth (EMY). From new
                            opportunities in education to life-changing internships, their stories show the real impact of our work. Let their
                            experiences inspire you!
                        </p>
                        <TestimonialSlider />
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default HomePage;