import React, { ReactNode, useEffect, useState } from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";

// ScrollToTop component
interface ScrollToTopProps {
    children: ReactNode;
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ children }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return <>{children}</>;
};

// Custom link component that scrolls to top
interface ScrollLinkProps extends Omit<LinkProps, 'to'> {
    to: string;
    children: ReactNode;
}

const ScrollLink: React.FC<ScrollLinkProps> = ({ children, to, ...props }) => {
    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Link to={to} onClick={handleClick} {...props}>
            {children}
        </Link>
    );
};

const Header: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 10);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const navItems = [
        { name: "Home", path: "/" },
        { name: "Programs", path: "/programs" },
        { name: "About Us", path: "/about" },
        { name: "Get Involved", path: "/get-involved" },
        { name: "Contact Us", path: "/contact" },
    ];

    return (
        <ScrollToTop>
            <header
                className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? "bg-white shadow-md" : "bg-transparent"
                    }`}
            >
                <div className="max-w-6xl mx-auto px-4">
                    <div className="flex justify-between items-center h-20">
                        <ScrollLink to="/" className="flex items-center">
                            <img
                                src="/logo.png"
                                alt="EMY Logo"
                                className={`transition-all duration-300 ${isScrolled ? "h-8" : "h-8"
                                    } w-auto`}
                            />
                        </ScrollLink>
                        <div className="hidden md:flex items-center space-x-6">
                            <nav>
                                <ul className="flex space-x-6">
                                    {navItems.map((item) => (
                                        <li key={item.name}>
                                            <ScrollLink
                                                to={item.path}
                                                className={`text-lg font-medium transition-colors duration-300 ${location.pathname === item.path
                                                    ? "text-green-600"
                                                    : isScrolled
                                                        ? "text-gray-700 hover:text-green-600"
                                                        : "text-black hover:text-green-400"
                                                    }`}
                                            >
                                                {item.name}
                                            </ScrollLink>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                            <ScrollLink
                                to="/donate"
                                className={`bg-red-500 text-white px-6 py-3 rounded-full hover:bg-red-600 transition-colors duration-300 ${isScrolled ? "shadow-md" : ""
                                    }`}
                            >
                                DONATE
                            </ScrollLink>
                        </div>
                        <div className="md:hidden">
                            <button
                                onClick={() => setIsMenuOpen(!isMenuOpen)}
                                className={`text-4xl focus:outline-none transition-colors duration-300 ${isScrolled ? "text-gray-700" : "text-black"
                                    }`}
                                aria-label="Toggle menu"
                            >
                                {isMenuOpen ? "×" : "☰"}
                            </button>
                        </div>
                    </div>
                </div>
                {/* Mobile menu */}
                <div
                    className={`md:hidden fixed inset-0 z-40 bg-white transform ${isMenuOpen ? "translate-x-0" : "translate-x-full"
                        } transition-transform duration-300 ease-in-out`}
                >
                    <div className="flex flex-col h-full">
                        <div className="flex justify-between items-center p-4 border-b">
                            <ScrollLink to="/" className="flex items-center" onClick={() => setIsMenuOpen(false)}>
                                <img src="/logo.png" alt="EMY Logo" className="h-8 w-auto" />
                            </ScrollLink>
                            <button
                                onClick={() => setIsMenuOpen(false)}
                                className="text-4xl text-gray-700 focus:outline-none"
                                aria-label="Close menu"
                            >
                                ×
                            </button>
                        </div>
                        <nav className="flex-grow">
                            <ul className="flex flex-col space-y-4 p-4">
                                {navItems.map((item) => (
                                    <li key={item.name}>
                                        <ScrollLink
                                            to={item.path}
                                            className={`block text-xl font-medium py-2 ${location.pathname === item.path
                                                ? "text-green-600"
                                                : "text-gray-700 hover:text-green-600"
                                                }`}
                                            onClick={() => setIsMenuOpen(false)}
                                        >
                                            {item.name}
                                        </ScrollLink>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                        <div className="p-4 border-t">
                            <ScrollLink
                                to="/donate"
                                className="block w-full bg-red-500 text-white text-center px-6 py-3 rounded-full hover:bg-red-600 transition-colors duration-300"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                DONATE
                            </ScrollLink>
                        </div>
                    </div>
                </div>
            </header>
            {/* Spacer div to push content below fixed header */}
            <div className="h-20"></div>
        </ScrollToTop>
    );
};

export default Header;